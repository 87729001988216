import SyConfigAPI from "../../global/compositionAPI/SyConfigAPI";

export default function GlobalJSAPI() {
  const {
    syConfigsValue: globalJsFragment,
  } = SyConfigAPI({ value: "global_js_fragment" });

  const executeGlobalJsFragment = () => {
    if (globalJsFragment.value?.wert) {
      // Diese Benutzung von eval ist eine Ausnahme
      // Bitte niemals an anderen Stellen eval wegen Sicherheitslücken verwenden
      // eslint-disable-next-line no-eval
      eval(globalJsFragment.value?.wert);
    }
  };

  return {
    executeGlobalJsFragment,
  };
}
