const AnkuendigungDetails = () => import(/* webpackChunkName: "AnkuendigungDetails" */ "../../views/Ankuendigung/AnkuendigungDetails/AnkuendigungDetails.vue");
const AnkuendigungList = () => import(/* webpackChunkName: "AnkuendigungList" */ "../../views/Ankuendigung/AnkuendigungList/AnkuendigungList.vue");
const AnonymisierungList = () => import(/* webpackChunkName: "AnonymisierungList" */ "../../views/Anonymisierung/AnonymisierungList/AnonymisierungList.vue");
const Barrierefreiheit = () => import(/* webpackChunkName: "Barrierefreiheit" */ "../../views/Templates/Barrierefreiheit/Barrierefreiheit.vue");
const BerichteDetails = () => import(/* webpackChunkName: "BerichteDetails" */ "../../views/Berichte/BerichteDetails/BerichteDetails.vue");
const BerichteList = () => import(/* webpackChunkName: "BerichteList" */ "../../views/Berichte/BerichteList/BerichteList.vue");
const BerichteVerwaltungDetails = () => import(/* webpackChunkName: "BerichteVerwaltungDetails" */ "../../views/Berichte/BerichteVerwaltungDetails/BerichteVerwaltungDetails.vue");
const BerichteVerwaltungList = () => import(/* webpackChunkName: "BerichteVerwaltungList" */ "../../views/Berichte/BerichteVerwaltungList/BerichteVerwaltungList.vue");
const BudgetDetails = () => import(/* webpackChunkName: "BudgetDetails" */ "../../views/Budget/BudgetDetails/BudgetDetails.vue");
const BudgetList = () => import(/* webpackChunkName: "BudgetList" */ "../../views/Budget/BudgetList/BudgetList.vue");
const Calendar = () => import(/* webpackChunkName: "Calendar" */ "../../views/Calendar/Calendar/Calendar.vue");
const CalendarList = () => import(/* webpackChunkName: "CalendarList" */ "../../views/Calendar/CalendarList/CalendarList.vue");
const CodeList = () => import(/* webpackChunkName: "CodeList" */ "../../views/Code/CodeList/CodeList.vue");
const Contact = () => import(/* webpackChunkName: "Contact" */ "../../views/Templates/contact/contact.vue");
const CookieRichtlinien = () => import(/* webpackChunkName: "CookieRichtlinien" */ "../../views/Templates/cookie-richtlinien/cookie-richtlinien.vue");
const DashcoardPublic = () => import(/* webpackChunkName: "Contact" */ "../../views/Dashboard/DashboardPublic/DashboardPublic.vue");
const DatenexportList = () => import(/* webpackChunkName: "DatenexportList" */ "../../views/Datenexport/DatenexportList/DatenexportList.vue");
const Datenschutz = () => import(/* webpackChunkName: "Datenschutz" */ "../../views/Templates/datenschutz/datenschutz.vue");
const DeckblattGenerieren = () => import(/* webpackChunkName: "DeckblattGenerieren" */ "../../views/Deckblatt/DeckblattGenerieren/DeckblattGenerieren.vue");
const DeckblattList = () => import(/* webpackChunkName: "DeckblattList" */ "../../views/Deckblatt/DeckblattList/DeckblattList.vue");
const Dokumentation = () => import(/* webpackChunkName: "FiBuManuelleBuchungsaktionDetails" */ "../../views/Dokumentation/Dokumentation.vue");
const DokumenteZurFoerderungList = () => import(/* webpackChunkName: "FiBuManuelleBuchungsaktionDetails" */ "../../views/Hilfe/DokumenteZurFoerderungList/DokumenteZurFoerderungList.vue");
const DynamicRoot = () => import(/* webpackChunkName: "DynamicRoot" */ "../../views/DynamicRoot/DynamicRoot.vue");
const FaqAdminDetails = () => import(/* webpackChunkName: "FaqAdminDetails" */ "../../views/Faq/FaqAdminDetails/FaqAdminDetails.vue");
const FaqAdminList = () => import(/* webpackChunkName: "FaqAdminList" */ "../../views/Faq/FaqAdminList/FaqAdminList.vue");
const FaqDetails = () => import(/* webpackChunkName: "FaqDetails" */ "../../views/Faq/FaqDetails/FaqDetails.vue");
const FiBuJournalList = () => import(/* webpackChunkName: "FiBuJournalList" */ "../../views/FiBu/FiBuJournalList/FiBuJournalList.vue");
const FiBuManuelleBuchungsaktionDetails = () => import(/* webpackChunkName: "FiBuManuelleBuchungsaktionDetails" */ "../../views/FiBu/FiBuManuelleBuchungsaktionDetails/FiBuManuelleBuchungsaktionDetails.vue");
const FoerderfinderFoerderangebotDetails = () => import(/* webpackChunkName: "FoerderfinderFoerderangebotDetails" */ "../../views/Foerderfinder/FoerderfinderFoerderangebotDetails/FoerderfinderFoerderangebotDetails.vue");
const FoerderfinderList = () => import(/* webpackChunkName: "FoerderfinderList" */ "../../views/Foerderfinder/FoerderfinderList/FoerderfinderList.vue");
const FoerderlandkartePage = () => import(/* webpackChunkName: "FoerderlandkartePage" */ "../../views/Foerderlandkarte/FoerderlandkartePage/FoerderlandkartePage.vue");
const GlobaleSuche = () => import(/* webpackChunkName: "GlobaleSuche" */ "../../views/GlobaleSuche/GlobaleSuche.vue");
const Hilfe = () => import(/* webpackChunkName: "Hilfe" */ "../../views/Hilfe/Hilfe/Hilfe.vue");
const IconsList = () => import(/* webpackChunkName: "IconsList" */ "../../views/Icons/IconsList/IconsList.vue");
const Impressum = () => import(/* webpackChunkName: "Impressum" */ "../../views/Templates/impressum/impressum.vue");
const KatalogeAdminDetails = () => import(/* webpackChunkName: "KatalogeAdminDetails" */ "../../views/Kataloge/KatalogeAdminDetails/KatalogeAdminDetails.vue");
const KatalogeAdminList = () => import(/* webpackChunkName: "KatalogeAdminList" */ "../../views/Kataloge/KatalogeAdminList/KatalogeAdminList.vue");
const LogList = () => import(/* webpackChunkName: "LogList" */ "../../views/Log/LogList/LogList.vue");
const NutzerDatenSucheList = () => import(/* webpackChunkName: "NutzerDatenSucheList" */ "../../views/Nutzer/NutzerDatenSucheList/NutzerDatenSucheList.vue");
const PersonalDetails = () => import(/* webpackChunkName: "PersonalDetails" */ "../../views/Personal/PersonalDetails/PersonalDetails.vue");
const PersonalList = () => import(/* webpackChunkName: "PersonalList" */ "../../views/Personal/PersonalList/PersonalList.vue");
const ProjektberichteDetails = () => import(/* webpackChunkName: "ProjektberichteDetails" */ "../../views/Projektbericht/ProjektberichteDetails/ProjektberichteDetails.vue");
const ProjektberichtVorlagenDetails = () => import(/* webpackChunkName: "ProjektberichtVorlagenDetails" */ "../../views/Projektbericht/ProjektberichtVorlagenDetails/ProjektberichtVorlagenDetails.vue");
const ProjektberichtVorlagenList = () => import(/* webpackChunkName: "ProjektberichtVorlagenList" */ "../../views/Projektbericht/ProjektberichtVorlagentList/ProjektberichtVorlagenList.vue");
const ProjektberichtList = () => import(/* webpackChunkName: "ProjektberichtList" */ "../../views/Projektbericht/ProjektberichtList/ProjektberichtList.vue");
const SachberichtvorlageDetails = () => import(/* webpackChunkName: "SachberichtvorlageDetails" */ "../../views/Sachbericht/SachberichtvorlageDetails/SachberichtvorlageDetails.vue");
const SachberichtVorlagen = () => import(/* webpackChunkName: "SachberichtVorlagen" */ "../../views/Sachbericht/SachberichtList/SachberichtList.vue");
const SammelvertragDetails = () => import(/* webpackChunkName: "SammelvertragDetails" */ "../../views/Sammelvertrag/SammelvertragDetails/SammelvertragDetails.vue");
const SammelvertragList = () => import(/* webpackChunkName: "SammelvertragList" */ "../../views/Sammelvertrag/SammelvertragList/SammelvertragList.vue");
const StartDetails = () => import(/* webpackChunkName: "StartDetails" */ "../../views/Start/StartDetails/StartDetails.vue");
const Styleguide = () => import(/* webpackChunkName: "Styleguide" */ "../../views/Styleguide/Styleguide.vue");
const SystemKonfigurationDetails = () => import(/* webpackChunkName: "SystemKonfigurationDetails" */ "../../views/System/SystemKonfigurationDetails/SystemKonfigurationDetails.vue");
const SystemKonfigurationList = () => import(/* webpackChunkName: "SystemKonfigurationList" */ "../../views/System/SystemKonfigurationList/SystemKonfigurationList.vue");
const TableauDetails = () => import(/* webpackChunkName: "TableauDetails" */ "../../views/TableauDetails/TableauDetails.vue");
const TaskLogDetails = () => import(/* webpackChunkName: "TaskLogDetails" */ "../../views/TaskLog/TaskLogDetails/TaskLogDetails.vue");
const TaskLogList = () => import(/* webpackChunkName: "TaskLogList" */ "../../views/TaskLog/TaskLogList/TaskLogList.vue");
const TeamsDetails = () => import(/* webpackChunkName: "TeamsDetails" */ "../../views/Teams/TeamsDetails/TeamsDetails.vue");
const TeamsList = () => import(/* webpackChunkName: "TeamsList" */ "../../views/Teams/TeamsList/TeamsList.vue");
const TeamSteuerung = () => import(/* webpackChunkName: "TeamSteuerung" */ "../../views/Teams/TeamSteuerung/TeamSteuerung.vue");
const TemplateAdminDetails = () => import(/* webpackChunkName: "TemplateAdminDetails" */ "../../views/DynamischeTemplates/TemplateAdminDetails/TemplateAdminDetails.vue");
const TemplateAdminList = () => import(/* webpackChunkName: "TemplateAdminList" */ "../../views/DynamischeTemplates/TemplateAdminList/TemplateAdminList.vue");
const TrackingDetails = () => import(/* webpackChunkName: "TrackingDetails" */ "../../views/Tracking/TrackingDetails/TrackingDetails.vue");
const VersionsinformationenDetails = () => import(/* webpackChunkName: "VersionsinformationenDetails" */ "../../views/Versionsinformationen/VersionsinformationenDetails/VersionsinformationenDetails.vue");
const VersionsinformationenList = () => import(/* webpackChunkName: "VersionsinformationenList" */ "../../views/Versionsinformationen/VersionsinformationenList/VersionsinformationenList.vue");

export default [
  {
    path: "/",
    name: "root.home",
    component: StartDetails,
  },
  {
    path: "/foerderfinderangebote/",
    name: "root.foerderfinderangebote",
    component: FoerderfinderList,
    meta: {
      instance_pages: "foerderfinderangebote"
    },
  },
  {
    path: "/foerderfinderangebote/:id/",
    name: "root.foerderfinderangebote.detail",
    component: FoerderfinderFoerderangebotDetails,
    meta: {
      instance_pages: "foerderfinderangeboteDetails"
    },
  },
  {
    path: "/hilfe/",
    name: "root.hilfen",
    component: Hilfe,
  },
  {
    path: "/foerderlandkarte/",
    name: "root.karte",
    component: FoerderlandkartePage,
    meta: {
      instance_pages: "foerderlandkarte"
    },
  },
  {
    path: "/foerderlandkarte_iframe/",
    name: "root.karte_iframe",
    component: FoerderlandkartePage,
    meta: {
      iframe: true
    },
  },
  {
    path: "/roots/:id/",
    name: "root.dynamicroot",
    component: DynamicRoot,
  },
  {
    path: "/kontakt/",
    name: "root.kontakt",
    component: Contact,
  },
  {
    path: "/berichte/:id/",
    name: "root.berichte.details",
    component: BerichteDetails,
    meta: {
      permissions: [
        "berichte.view",
      ],
    },
  },
  {
    path: "/budget/",
    name: "root.budget",
    component: BudgetList,
    meta: {
      permissions: [
        "budget.view",
      ],
    },
  },
  {
    path: "/budget/:id/",
    name: "root.budget.details",
    component: BudgetDetails,
    meta: {
      permissions: [
        "budget.view",
      ],
    },
  },
  {
    path: "/codes/",
    name: "root.codes",
    component: CodeList,
    meta: {
      permissions: [
        "codes.ui",
      ],
    },
  },
  {
    path: "/anonymisierungsauftraege/",
    name: "root.anonymisierung",
    component: AnonymisierungList,
    meta: {
      permissions: [
        "anonymisierung.view",
      ],
    },
  },
  {
    path: "/templates/",
    name: "root.templateadmin",
    component: TemplateAdminList,
  },
  {
    path: "/templates/:id/",
    name: "root.templateadmin.details",
    component: TemplateAdminDetails,
  },
  {
    path: "/icons/",
    name: "root.icons",
    component: IconsList,
    meta: {
      permissions: [
        "asset.admin_app_view",
      ],
    },
  },
  {
    path: "/kataloge/",
    name: "root.katalogeadmin",
    component: KatalogeAdminList,
    meta: {
      permissions: [
        "kataloge.admin.view",
      ],
    },
  },
  {
    path: "/kataloge/:id/",
    name: "root.katalogeadmin.details",
    component: KatalogeAdminDetails,
    meta: {
      permissions: [
        "kataloge.admin.view",
      ],
    },
  },
  {
    path: "/kataloge-menu/:id/",
    name: "root.kataloge_admin_menu_details",
    component: KatalogeAdminDetails,
    meta: {
      permissions: [
        "kataloge.admin.view",
      ],
    },
    props: {
      inMenu: true,
    },
  },
  {
    path: "/log/service/",
    name: "root.servicelog",
    component: LogList,
    meta: {
      permissions: [
        "log.service.view",
      ],
    },
  },
  {
    path: "/ankuendigung/",
    name: "root.news",
    component: AnkuendigungList,
    meta: {
      permissions: [
        "news.create",
      ],
    },
  },
  {
    path: "/ankuendigung/:id/",
    name: "root.news.details",
    component: AnkuendigungDetails,
    meta: {
      permissions: [
        "news.create",
      ],
    },
  },
  {
    path: "/releasenotes/",
    name: "root.releasenotes",
    component: VersionsinformationenList,
    meta: {
      permissions: [
        "releasenotes.view",
      ],
    },
  },
  {
    path: "/releasenotes/:id/",
    name: "root.releasenotes.details",
    component: VersionsinformationenDetails,
    meta: {
      permissions: [
        "releasenotes.update",
      ],
    },
  },
  {
    path: "/syconfigadmin/",
    name: "root.syconfigadmin",
    component: SystemKonfigurationList,
    meta: {
      permissions: [
        "syconfigadmin.view",
      ],
    },
  },
  {
    path: "/syconfigadmin/:kennung/",
    name: "root.syconfigadmin.details",
    component: SystemKonfigurationDetails,
    meta: {
      permissions: [
        "syconfigadmin.view",
      ],
    },
  },
  {
    path: "/tracking/",
    name: "root.tracking",
    component: TrackingDetails,
    meta: {
      permissions: [
        "foerderantrag.view",
      ],
    },
  },
  {
    path: "/datenexport/",
    name: "root.datenexport",
    component: DatenexportList,
    meta: {
      permissions: [
        "datenexport.ui",
      ],
    },
  },
  {
    path: "/deckblatt/",
    name: "root.deckblatt",
    component: DeckblattList,
    meta: {
      permissions: [
        "deckblatt.view",
      ],
    },
  },
  {
    path: "/deckblatt/generieren",
    name: "root.deckblatt_generieren",
    component: DeckblattGenerieren,
    meta: {
      permissions: [
        "deckblatt.create",
      ],
    },
  },
  {
    path: "/faqs/",
    name: "root.faqs",
    component: FaqDetails,
  },
  {
    path: "/admin-faqs/",
    name: "root.adminfaqs",
    component: FaqAdminList,
    meta: {
      permissions: [
        "faq.rubrik.create",
      ],
    },
  },
  {
    path: "/admin-faqs/:id/",
    name: "root.adminfaqs.details",
    component: FaqAdminDetails,
    meta: {
      permissions: [
        "faq.rubrik.create",
      ],
    },
  },
  {
    path: "/fibujournal/",
    name: "root.fibujournal",
    component: FiBuJournalList,
    meta: {
      permissions: [
        "fibu.view",
      ],
    },
  },
  {
    path: "/fibujournal/buchung-erstellen/:id/",
    name: "root.fibujournal.manuelle_buchungsaktion",
    component: FiBuManuelleBuchungsaktionDetails,
    meta: {
      permissions: [
        "fibu.manuelle_buchungsaktion.execute",
      ],
    },
  },
  {
    path: "/dokumente-zur-foerderung/",
    name: "root.dokumente-zur-foerderung",
    component: DokumenteZurFoerderungList,
  },
  {
    path: "/dokumentation/",
    name: "root.dokumentation",
    component: Dokumentation,
    meta: {
      permissions: [
        "dokumentation.view",
      ],
    },
  },
  {
    path: "/dashboard_alternative/",
    name: "root.dashboard_alternative",
    component: DashcoardPublic,
  },
  {
    path: "/calendar/",
    name: "root.calendar",
    component: Calendar,
    meta: {
      permissions: [
        "termin.view",
      ],
    },
  },
  {
    path: "/terminliste/",
    name: "root.terminliste",
    component: CalendarList,
    meta: {
      permissions: [
        "termin.view",
      ],
    },
  },
  {
    path: "/personal/",
    name: "root.personal",
    component: PersonalList,
    meta: {
      permissions: [
        "personal.ui",
      ],
    },
  },
  {
    path: "/personal/:id/",
    name: "root.personal.details",
    component: PersonalDetails,
    meta: {
      permissions: [
        "personal.ui",
      ],
    },
  },
  {
    path: "/nutzerdatensuche/",
    name: "root.nutzerdatensuche",
    component: NutzerDatenSucheList,
    meta: {
      permissions: [
        "nutzerdatensuche.view",
      ],
    },
  },
  {
    path: "/projektberichte/",
    name: "root.projektberichte",
    component: ProjektberichtList,
    meta: {
      permissions: [
        "projektbericht.view",
      ],
    },
  },
  {
    path: "/projektberichte/:id/",
    name: "root.projektberichte.detail",
    component: ProjektberichteDetails,
    meta: {
      permissions: [
        "projektbericht.view",
      ],
    },
  },
  {
    path: "/projektberichtvorlagen/",
    name: "root.projektberichtvorlagen",
    component: ProjektberichtVorlagenList,
    meta: {
      permissions: [
        "projektberichtvorlage.view",
      ],
    },
  },
  {
    path: "/projektberichtvorlagen/:id/",
    name: "root.projektberichtvorlagen.detail",
    component: ProjektberichtVorlagenDetails,
    meta: {
      permissions: [
        "projektberichtvorlage.view",
      ],
    },
  },
  {
    path: "/sachberichtvorlagen/",
    name: "root.sachberichtvorlagen",
    component: SachberichtVorlagen,
    meta: {
      permissions: [
        "sachberichtvorlage.view",
      ],
    },
  },
  {
    path: "/sachberichtvorlagen/:id/",
    name: "root.sachberichtvorlagen.detail",
    component: SachberichtvorlageDetails,
    meta: {
      permissions: [
        "sachberichtvorlage.view",
      ],
    },
  },
  {
    path: "/sammelvertraege/",
    name: "root.sammelvertrag",
    component: SammelvertragList,
    meta: {
      permissions: [
        "sammelvertrag.view",
      ],
    },
  },
  {
    path: "/sammelvertraege/:id/",
    name: "root.sammelvertrag.details",
    component: SammelvertragDetails,
    meta: {
      permissions: [
        "sammelvertrag.view",
      ],
    },
  },
  {
    path: "/suche/",
    name: "root.suche",
    component: GlobaleSuche,
    meta: {
      permissions: [
        "suche.view",
      ],
    },
  },
  {
    path: "/tableau/",
    name: "root.tableau",
    component: BerichteList,
    meta: {
      permissions: [
        "berichte.view",
      ],
    },
  },
  {
    path: "/tableau/:id/",
    name: "root.tableau.detail",
    component: TableauDetails,
    meta: {
      permissions: [
        "tableau.view",
      ],
    },
  },
  {
    path: "/tableau/verwaltung/",
    name: "root.tableau.admin",
    component: BerichteVerwaltungList,
    meta: {
      permissions: [
        "berichte.update",
      ],
    },
  },
  {
    path: "/tableau/verwaltung/:id/",
    name: "root.tableau.admin.detail",
    component: BerichteVerwaltungDetails,
    meta: {
      permissions: [
        "berichte.update",
      ],
    },
  },
  {
    path: "/hintergrundaufgaben/",
    name: "root.tasklog",
    component: TaskLogList,
    meta: {
      permissions: [
        "tasklog.list",
      ],
    },
  },
  {
    path: "/hintergrundaufgaben/:id/",
    name: "root.tasklog.detail",
    component: TaskLogDetails,
    meta: {
      permissions: [
        "tasklog.list",
      ],
    },
  },
  {
    path: "/teams/",
    name: "root.teams",
    component: TeamsList,
    meta: {
      permissions: [
        "teammitglied.create",
      ],
    },
  },
  {
    path: "/teams/:id/",
    name: "root.teams.details",
    component: TeamsDetails,
    meta: {
      permissions: [
        "teammitglied.create",
      ],
    },
  },
  {
    path: "/teamsteuerung/",
    name: "root.routen",
    component: TeamSteuerung,
    meta: {
      permissions: [
        "teamrouting.view",
      ],
    },
  },
  {
    path: "/impressum/",
    name: "root.impressum",
    component: Impressum,
    props: {
      class: "col-lg-6 a_p_0",
    },
  },
  {
    path: "/datenschutz/",
    name: "root.datenschutz",
    component: Datenschutz,
    props: {
      class: "col-lg-6 a_p_0",
    },
  },
  {
    path: "/cookie-richtlinien/",
    name: "root.cookierichtlinien",
    component: CookieRichtlinien,
    props: {
      class: "col-lg-6 a_p_0",
    },
  },
  {
    path: "/barrierefreiheit/",
    name: "root.barrierefreiheit",
    component: Barrierefreiheit,
    props: {
      class: "col-lg-6 a_p_0",
    },
  },
  {
    path: "/vuestyleguide/",
    name: "root.vuestyleguide",
    component: Styleguide,
  },
];
